
































































































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import {
  CustomListCategory,
  DataListAssetBook,
  DataListMasterAsset,
} from "@/models/interface/assets.interface";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import { masterServices } from "@/services/master.service";
import { currencyFormat } from "@/validator/globalvalidator";
import moment from "moment";
import Vue from "vue";
import printJs from "print-js";
import { logisticServices } from "@/services/logistic.service";
import { DataWarehouseLocation } from "@/models/interface/logistic.interface";

export default Vue.extend({
  data() {
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    this.getListLocation = debounceProcess(this.getListLocation, 200);
    this.getListAssetCategories = debounceProcess(
      this.getListAssetCategories,
      200
    );
    this.getListAssetStatus = debounceProcess(this.getListAssetStatus, 200);
    return {
      idForDetail: "" as string,
      visible: false as boolean,
      valueQr: [] as string[],
      unitCode: [] as string[],
      disabledButton: true as boolean,
      disabledButtonQr: true as boolean,
      selectedRowKeys: [],
      dataListCategory: [] as CustomListCategory[],
      dataListLocation: [] as DataWarehouseLocation[],
      dataListStatus: [] as ResponseListMaster[],
      totalElements: 0 as number,
      page: 1 as number,
      limit: 10 as number,
      dataListBook: [] as DataListAssetBook[],
      loadingListAssetBook: false as boolean,
      loadingLocation: false as boolean,
      loadingListAssetCategory: false as boolean,
      loadingFind: false as boolean,
      loadingStatus: false as boolean,
      dataSource: [] as DataListMasterAsset[],
      columnsTable: [
        {
          title: "Asset Number",
          dataIndex: "assetNo",
          key: "assetNo",
          width: 150,
        },
        {
          title: "Category",
          dataIndex: "categoryCustom",
          key: "categoryCustom",
          width: 250,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 120,
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 150,
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 150,
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          width: 200,
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "locationCustom",
          key: "locationCustom",
          width: 350,
          // ellipsis: true
        },
        {
          title: "Quantity",
          dataIndex: "unit",
          key: "unit",
          width: 150,
        },
        {
          title: "Life Months",
          dataIndex: "currentLifeMonths",
          key: "currentLiftMonths",
          width: 200,
        },
        {
          title: "Acquisition Date",
          dataIndex: "dateInServiceCustom",
          key: "dateInServiceCustom",
          width: 200,
        },
        {
          title: "Cost",
          dataIndex: "currentCostAsset",
          key: "currentCostAsset",
          width: 150,
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          width: 350,
        },
        {
          title: this.$t("lbl_customer_location"),
          dataIndex: "customerLocation",
          key: "customerLocation",
          width: 350,
        },
      ],
      form: this.$form.createForm(this, { name: "inquirySearch" }),
      formRules: {
        category: {
          label: "lbl_category",
          name: "category",
          placeholder: "lbl_category_placeholder",
          decorator: ["category"],
        },
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number_placeholder",
          decorator: ["assetNumber"],
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_status_placeholder",
          decorator: ["status"],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: ["serialNumber"],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: ["unitCode"],
        },
        location: {
          label: "lbl_location",
          name: "location",
          placeholder: "lbl_location_placeholder",
          decorator: ["location"],
        },
      },
    };
  },
  watch: {
    selectedRowKeys: function (value) {
      if (value.length === 1) {
        this.disabledButton = false;
        this.disabledButtonQr = false;
      } else if (value.length > 1) {
        this.disabledButtonQr = false;
        this.disabledButton = true;
      } else {
        this.disabledButtonQr = true;
        this.disabledButton = true;
      }
    },
  },
  methods: {
    getListAssetStatus(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: `ASSET_STATUS`,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `value~*${valueSearch}*`;

      this.loadingStatus = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataListStatus = data;
        })
        .finally(() => (this.loadingStatus = false));
    },
    handleCancel() {
      this.form.resetFields();
      this.dataSource = [];
      this.selectedRowKeys = [];
      this.totalElements = 0;
      this.page = 1;
      this.limit = 10;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.handleFind();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.handleFind();
    },
    handleOk() {
      this.visible = false;
      const params: RequestQueryParamsModel = {
        unitCodes: this.unitCode.join(","),
      };
      assetsServices.getPrintQr(params).then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          printJs(url);
        }
      });
    },
    handleViewQr() {
      let tempValueQr = [] as string[];
      let tempUnitCode = [] as string[];
      this.selectedRowKeys.forEach(data => {
        tempValueQr = [...tempValueQr, this.dataSource[data].unitCode];
        tempUnitCode = [...tempUnitCode, `${this.dataSource[data].unitCode}`];
      });
      this.valueQr = tempValueQr;
      this.unitCode = tempUnitCode;
      this.visible = true;
      this.valueQr = this.valueQr.slice();
      this.unitCode = this.unitCode.slice();
    },
    onSelectChange(value) {
      this.selectedRowKeys = value;
      if (value.length === 1) {
        this.idForDetail = this.dataSource[value[0]].id;
      }
    },
    assignSearch(key, value, and): string {
      if (key === "category" && value)
        return and + `assetCategory.secureId~*${value}*`;
      else if (key === "assetNumber" && value) return and + `assetNo~${value}`;
      else if (key === "status" && value) return and + `status~${value}`;
      else if (key === "serialNumber" && value)
        return and + `serialNumber~*${value}*`;
      else if (key === "unitCode" && value) return and + `unitCode~*${value}*`;
      else if (key === "location" && value)
        return and + `assetLocation.secureId~*${value}*`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    handleFind() {
      this.form.validateFields((err, res) => {
        if (err) return;
        let params = {
          page: this.page - 1,
          limit: this.limit,
          // search: `assetBookId~*${res.book}*`
        } as RequestQueryParamsModel;

        params.search = this.dynamicSearch(res);

        this.loadingFind = true;
        assetsServices
          .listMasterAsset(params)
          .then(data => {
            this.totalElements = data.totalElements;
            this.selectedRowKeys = [];
            this.dataSource = data.data.map(dataMap => {
              return {
                ...dataMap,
                currentCostAsset: currencyFormat(dataMap.currentCostAsset),
                locationCustom: `${dataMap.assetLocation.warehouse.branchWarehouse.name} ${dataMap.assetLocation.warehouse.branchWarehouse.address}`,
                categoryCustom: `${dataMap.assetCategory.id}`
                  .split(".")
                  .map(dataMapString => {
                    return dataMapString.replace(/\b[a-z](?=[a-z]{2})/g, e =>
                      e.toUpperCase()
                    );
                  })
                  .join("."),
                dateInServiceCustom: `${moment(dataMap.acquisitionDate).format(
                  "DD MMM yyyy"
                )}`,
              };
            });
          })
          .finally(() => (this.loadingFind = false));
      });
    },
    getListLocation(valueSearch) {
      const params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*_OR_name~*${valueSearch}*`;
      this.loadingLocation = true;
      logisticServices
        .listWarehouseLocation(params, "")
        .then(data => {
          this.dataListLocation = data.data;
        })
        .finally(() => (this.loadingLocation = false));
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        search: `type~commercial_OR_active~true`,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    getListAssetCategories(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `categoryId~*${valueSearch}*_OR_description~*${valueSearch}*`;
      this.loadingListAssetCategory = true;
      assetsServices
        .listAssetCategory(params)
        .then(response => {
          let tempDataSegments = [] as CustomListCategory[];
          response.data.forEach(data => {
            let tempSegment = {
              id: data.id,
              first: "",
              second: "",
              idForView: "",
            } as CustomListCategory;

            let idCategories = data.category.id.split(".");

            idCategories = idCategories.map(dataMap => {
              let temp = dataMap.split("");
              if (temp.length > 0) {
                temp[0] = temp[0].toUpperCase();
                dataMap = temp.join("");
              }
              return dataMap;
            });

            tempSegment.idForView = idCategories.join(".");
            tempDataSegments.push(tempSegment);
          });
          this.dataListCategory = tempDataSegments;
        })
        .finally(() => (this.loadingListAssetCategory = false));
    },
    goToAnotherPage(page) {
      switch (page) {
        case "retirement":
          this.$router.push(`/inquiry/retirement/update/${this.idForDetail}`);
          break;
        case "adjustment":
          this.$router.push(`/inquiry/adjustment/update/${this.idForDetail}`);
          break;
        case "details":
          this.$router.push(`/inquiry/open/${this.idForDetail}`);
          break;
        default:
          break;
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getListAssetCategories("");
    this.getListLocation("");
    this.getListAssetBook("");
    this.getListAssetStatus("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
